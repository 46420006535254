import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.scss";

const mainMenu = [
    {name: 'Home', url: '/'},
    {name: 'Mission', url: '/mission'},
    {name: 'Our Programs', url: '/our-programs'},
    {name: 'Club Staff', url: '/club-staff'},
    {name: 'Women', url: '/women'},
    {name: 'Men', url: '/men'},
    {name: 'Universities', url: '/universities'},
    {name: 'Contact', url: '/contact'},
    {name: 'Camps', url: '/camps'},
]

export default function  Menu(props){
    const location = useLocation()
    return (
        <nav className="menu-container">
            <ul className="menu">
                {mainMenu.map((item, index) => {
                    const {name, url} = item;
                    const currentRoute = url === location.pathname ? "current-page" : "";
                    return (
                        <li key={index} id={currentRoute} className="menu-item" onClick={props.handleClick ?? null}>
                            <Link 
                                to={url}
                                className="menu-item-link"
                            >
                                {name}
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </nav>
  )
}
