import "./style.scss"
import Menu from "../Menu/"
import React, { useState, useCallback } from "react";

export default function SideBar() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSideBar = useCallback(() => {
        setIsOpen(isOpen => !isOpen)
    }, [])

    return (
        <>
            <div className="btn btn-primary open-menu-btn" onClick={toggleSideBar} >
                <i className="fa fa-bars"></i>
            </div>

            <div className={`sidebar ${isOpen === true ? 'active' : ''}`}>
                <div className="sd-header">
                    <h4 className="mb-0">Main Menu</h4>
                    <div className="btn btn-primary close-menu-btn" onClick={toggleSideBar}>
                        <i className="fa fa-times"></i>
                    </div>
                </div>
                <div className="sd-body">
                    <Menu handleClick={toggleSideBar}/>
                </div>
            </div>
            <div className={`sidebar-overlay ${isOpen === true ? 'active' : ''}`} onClick={toggleSideBar}></div>
        </>
    )
}
