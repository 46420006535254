import React from 'react'
import './style.scss'

export default function SocialMediaLinks() {
    return (
        <div className="socialmedia-links-container">
            <a className="socialmedia-link instagram" href="https://www.instagram.com/sportingclubmadrid/" target="_blank" rel="noopener noreferrer">
                <span className="hidden-link-text">Instagram</span>
            </a>
            <a className="socialmedia-link youtube" href="https://www.youtube.com/@sportingclubmadrid3118" target="_blank" rel="noopener noreferrer">
                <span className="hidden-link-text">Youtube</span>
            </a>
            <a className="socialmedia-link facebook" href="https://www.facebook.com/SportingClubMadrid/" target="_blank" rel="noopener noreferrer">
                <span className="hidden-link-text">Facebook</span>
            </a>
            <a className="socialmedia-link twitter" href="https://mobile.twitter.com/scmadrid2021" target="_blank" rel="noopener noreferrer">
                <span className="hidden-link-text">Twitter</span>
            </a>
            <a className="socialmedia-link linkedin" href="https://es.linkedin.com/company/sporting-club-madrid" target="_blank" rel="noopener noreferrer">
                <span className="hidden-link-text">Linkedin</span>
            </a>
        </div>
    )
}

