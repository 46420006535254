import './style.scss'
import Menu from '../Menu/'
import SocialMediaLinks from '../SocialMediaLinks/'
import Partners from '../Partners/'

import JomaLogo from './assets/joma-logo.png'
import RFFMLogo from './assets/rffm-logo.webp'
import RFEFLogo from './assets/rfef-logo.png'

const sponsors = [
    {name: "Real Federacion Española de Futbol", url: "https://www.rfef.es/", img: RFEFLogo},
    {name: "Real Federación de Fútbol de Madrid", url: "https://www.rffm.es/", img: RFFMLogo},
    {name: "Joma Sport", url: "https://www.joma-sport.com/", img: JomaLogo},
]

export default function Footer() {
    return (
        <div className="pg-footer">
            <footer className="footer">
                <svg className="footer-wave-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 100" preserveAspectRatio="none">
                    <path className="footer-wave-path" d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"></path>
                </svg>

                <div className="footer-content">
                    <div className="footer-logo">
                        <a className="footer-logo-link" href="/">
                            <span className="hidden-link-text">LOGO</span>
                            <h1>SPORTING CLUB MADRID</h1>
                        </a>
                    </div>

                    <div className="footer-content-columns">
                        <div className="footer-content-column">
                            <div className="footer-menu">
                                <h2 className="footer-menu-name">Join us!</h2>
                                <Menu/>
                            </div>
                        </div>

                        <div className="footer-content-column snp-container">
                            <Partners sponsors={sponsors}/>
                        </div>
                    </div>
                </div>

                <SocialMediaLinks/>

                <div className="footer-copyright">
                    <p className="footer-copyright-text">
                        {`Copyright © 2020 - ${new Date().getFullYear()} Sporting Club Madrid. | All rights reserved.`}
                    </p>
                </div>
            </footer>
        </div>
    )
}

/*
                    <ul id="menu-get-started" className="footer-menu-list">
                      <li className="menu-item menu-item-type-custom menu-item-object-custom">
                        <a href="/">Home</a>
                      </li>
                      <li className="menu-item menu-item-type-custom menu-item-object-custom">
                        <a href="/mission">Mission</a>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page">
                        <a href="/our-program">Our Program</a>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page">
                        <a href="/club-staff">Club Staff</a>
                      </li>
                      <li className="menu-item menu-item-type-post_type_archive menu-item-object-customer">
                        <a href="/women">Women</a></li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page">
                        <a href="/men">Men</a>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page">
                        <a href="/universities">Universities</a>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page">
                        <a href="/contact">Contact</a>
                      </li>
                    </ul>
*/
