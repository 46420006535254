import './App.scss';
import { Routes, Route, useLocation } from 'react-router-dom';
import React, { lazy, Suspense } from "react";
import HeaderLogo from './components/HeaderLogo/';
import Footer from './components/Footer/';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Loader from './components/utils/Loader';

/* Lazy Load Pages */
const HomePage = lazy(() => import('./pages/Home/'))
const MenAPage = lazy(() => import('./pages/MenA/'))
const WomenPage = lazy(() => import('./pages/Women/'))
const MissionPage = lazy(() => import('./pages/Mission/'))
const UniversityPage = lazy(() => import('./pages/University/'))
const Error404Page = lazy(() => import('./pages/404/'))
const CampsPage = lazy(() => import('./pages/Camps/'))
const OurProgramsPages = lazy(() => import('./pages/OurPrograms/'))
const ClubStaffPage = lazy(() => import('./pages/ClubStaff/'))
const ContactFormSubmissionPage = lazy(() => import('./pages/ContactFormSubmission/'))
const Contact = lazy(() => import('./components/Contact/'))


export default function App() {
  const location = useLocation();

  return (
    <>
      {location.pathname !== '/pay' && <HeaderLogo />}
      <Routes>
        <Route path="*" exact element={<Suspense fallback={<Loader/>}><Error404Page /></Suspense>} />
        <Route path="/" exact element={<Suspense fallback={<Loader/>}><HomePage /></Suspense>} />
        <Route path="/contact" exact element={<Suspense fallback={<Loader/>}><Contact /></Suspense>} />
        <Route path="/contact-submission" exact element={<Suspense fallback={<Loader/>}><ContactFormSubmissionPage /></Suspense>} />
        <Route path="/dashboard" exact element={<Suspense fallback={<Loader/>}><HomePage /></Suspense>} />
        <Route path="/mission" exact element={<Suspense fallback={<Loader/>}><MissionPage /></Suspense>} />
        <Route path="/our-programs" exact element={<Suspense fallback={<Loader/>}><OurProgramsPages /></Suspense>} />
        <Route path="/club-staff" exact element={<Suspense fallback={<Loader/>}><ClubStaffPage /></Suspense>} />
        <Route path="/women" exact element={<Suspense fallback={<Loader/>}><WomenPage /></Suspense>} />
        <Route path="/men" exact element={<Suspense fallback={<Loader/>}><MenAPage /></Suspense>} />
        <Route path="/universities" exact element={<Suspense fallback={<Loader/>}><UniversityPage /></Suspense>} />
        <Route path="/camps" exact element={<Suspense fallback={<Loader/>}><CampsPage /></Suspense>} />
      </Routes>
      {location.pathname !== '/pay' && <ScrollToTop />}
      {location.pathname !== '/pay' && <Footer />}
    </>
  );
}

/* Components from the old version of the site that I currently do not want imported
 *

/* This component should be used with a generic path like "/blogpost/" that has an id variable appended to it i.e. "/blogpost/:id". 
 * Realistically this API call is not necessary until the actual component is loaded and the id can be used to load the single entry.

import Blog from './pages/Blog';
<Route path="/blog" exact element={<Suspense fallback={<Loader/>}><Blog /></Suspense>} />

import FullBlogPost from './components/FullBlogPost/FullBlogPost';

  const url2 = 'https://clever-neumann.82-223-205-248.plesk.page/api/posts'
  const [todos2, setTodos2] = useState()
  const fetchApi2 = async () => {
    const response2 = await fetch(url2)
    const responseJSON2 = await response2.json()
    setTodos2(responseJSON2.data)
  }

  useEffect(() => {
    fetchApi()
    fetchApi2()
  }, [])

        {!todos2 ? 'Cargando...' :
          todos2.map((post, index) => {
            return <Route key={index} path={"/" + post.attributes.Title.toLowerCase().replaceAll(" ", "-").replaceAll(",", "")} exact element={<FullBlogPost id={post.id} />} />
          })}

import Convocatoria from './components/Convocatoria/Convocatoria';
<Route path="/convocatoria" exact element={<Convocatoria />} />


/* This component should be used with a generic path "/player/" that has an id variable appended to it i.e. "/player/:id". 
 * Realistically this API call is not necessary until the actual component is loaded and the id can be used to load the single entry.

import PlayerPersonal from './components/PlayerPersonalPage/playerPersonal';

  const url = 'https://clever-neumann.82-223-205-248.plesk.page/api/players?pagination[page]=1&pagination[pageSize]=300'
  const [todos, setTodos] = useState()
  const fetchApi = async () => {
    const response = await fetch(url)
    const responseJSON = await response.json()
    setTodos(responseJSON.data)
  }

  useEffect(() => {
    fetchApi()
    fetchApi2()
  }, [])

        {!todos ? 'Cargando...' :
          todos.map((player, index) => {
            return <Route key={index} path={"/" + player.attributes.Name.toLowerCase() + "-" + player.attributes.Surname.toLowerCase().replaceAll(" ", "-").replaceAll("á", "a").replaceAll("é", "e").replaceAll("í", "i").replaceAll("ó", "o").replaceAll("ú", "u").replaceAll("ñ", "n")} exact element={<PlayerPersonal id={player.id} />} />
          })}

*/

/*

import BuyPage from './pages/BuyPage';
<Route path="/pay" exact element={<BuyPage />} />

*/
