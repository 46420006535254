import './style.scss';

export default function Partners(props) {
    const {sponsors} = props; 
    return (
        <>
            <h3 className="snp-heading">
                Our Sponsors
                <span className="text-theme-highlight"> and Partners</span>
                <hr className="heading-divider" />
            </h3>

            <div className="sponsors-container">
                {sponsors.map((sponsor, index) => {
                    const {img, name} = sponsor;
                    return (
                        <img key={index} className="flex-img" src={img} alt={name} />
                    )
                })}
            </div>
        </>
    )
}

/* Link wrapped images removed to prevent ad blockers from hiding content
                        <a 
                            key={index}
                            href={url}
                            className="sponsor-link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img className="flex-img" src={img} alt={name} />
                        </a>
 */
