import "./style.scss";
import Logo from "./assets/spm-logo.png";
import Menu from "../Menu/"
import Sidebar from "../SideBar/";
import SocialMediaLinks from "../SocialMediaLinks/";

import { Link } from "react-router-dom";

export default function HeaderLogo(){
    return (
        <div className='header-container'>
            <div className='main-header-container'>
                <Link to="/" id='logo-link'>
                    <img src={Logo} alt="Sporting Club Madrid Logo"/>
                </Link>
                <div id='header-title-container'>
                    <Link to="/" id='logo-link'>
                        <h1 id='title-header'>SPORTING CLUB MADRID</h1>
                    </Link>
                    <SocialMediaLinks/>
                </div>
            </div>

            <div className="header-menu">
                <Menu/>
            </div>

            <Sidebar />
      </div>
  )
}
